<template>
     <v-container fluid>
    <!-- class="grey lighten-5">  -->
    <header-app></header-app>
    <!--   RINCON GOURMET -->
    <!-- class="grey lighten-5">  -->

    <!--HEADER-->
    
    <!--HEADER FIN-->
    <v-row>
      <v-col>
        <v-card class="pa-2" dark :color=colorMenu>
              <v-card-tittle > Esta pagina permite realizar cambios en los distintos items del complejo, haga click sobre el cual quiera modificar</v-card-tittle>
        </v-card>
      </v-col>
    </v-row>
    <complejo-home :colorMenu=colorMenu :items1=items1 :items2=items2 :items3=items3  ></complejo-home>
     </v-container>

</template>
<script>
import headerApp from '@/components/headerApp.vue'
import complejoHome from '@/components/complejohome'
export default {
  components:{complejoHome , headerApp},
  data() {
    return {
       colorMenu:"#002A53",
      items1: [     
        {
          texto1: "Nuestra",
          texto2: "Carta",
          icono: "mdi-silverware",
          destino: "cartardeAdmin"
        },
        {
          texto1: "Rservar",
          texto2: "Mesa",
          icono: "mdi-clock-outline",
          destino: "reservarmesardeAdmin"
        },
        {
          texto1: "Menú",
          texto2: "del día",
          icono: "mdi-room-service-outline",
          destino: "roomservicerdeAdmin"
        }
      ],
      items2:[{
           texto1: "Normas del Complejo",
           icono: "mdi-book-open-page-variant",
           destino: "normasrdeAdmin"
           },
           {
             texto1: "Numeros Importantes",             
           icono: "mdi-phone-classic",
           destino: "telefonosrdeAdmin"
           }
      ],
      items3:[{        
           texto1: "Servicios",
           texto2: "del complejo",
           icono: "mdi-pool",
           destino: "serviciosrdeAdmin"
           },
           {
             texto1: "Actividades",
             texto2: "recreativas del día" ,            
           icono: "mdi-basketball",
           destino: "actividadesrdeAdmin"
           }
      ],
      miniVariant: false,
      title: "Rincon Club",
      activeBtn: null,
      activeBtn2: 0
    }
  },
  created: function() {
    document.title = "Rincon del Este MODO ADMIN";
  }
}
</script>
<style scoped>

</style>
